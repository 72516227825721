import {
  Box,
  chakra,
  Container,
  Link,
  Image,
  Stack,
  Text,
  Icon,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';
import Logo from '../../images/logos/logo-75.png';
import Swoop from '../../images/misc/swoop-ec.png';

const Links = [
  {friendly:'Home', path: '/'},
  {friendly: 'About', path: '/about'},
  {friendly: 'Services', path: '/services'},
  {friendly: 'Think Tank', path: '/think-tank'},
  {friendly: 'Contact', path: '/contact'}
];

const SocialButton = ({ icon, label, href }) => (
  <chakra.button
    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
    rounded="full"
    w={8}
    h={8}
    cursor="pointer"
    as="a"
    href={href}
    target="_blank"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    transition="background 0.3s ease"
    _hover={{
      bg: useColorModeValue('blackAlpha.300', 'whiteAlpha.300'),
    }}
  >
    <VisuallyHidden>{label}</VisuallyHidden>
    <Icon as={icon} />
  </chakra.button>
);

const LogoSection = () => (
  <Box display='flex' alignItems='center' flexDirection='column'>
    <Image filter="grayscale(1)" opacity=".75" h="50px" w="50px" src={Logo} alt="Footer Logo | Webletize Website Design and Development" />
    <Box fontFamily="Lexend Exa, sans-serif" letterSpacing="-2px" fontSize={24} fontWeight={600}>Webletize</Box>
  </Box>
);

const SocialLinks = () => (
  <Stack direction="row" spacing={6}>
    <SocialButton label="Facebook" href="https://facebook.com/webletize" icon={FaFacebookF} />
    <SocialButton label="Twitter" href="https://twitter.com/webletize" icon={FaTwitter} />
    <SocialButton label="YouTube" href="https://youtube.com/@Webletize" icon={FaYoutube} />
  </Stack>
);

export default function Footer() {
  return (
    <>
      <Box>
        <Image w="100%" maxH="250px" src={Swoop} alt="Webletize Swoop" />
      </Box>
      <Box bg="#ededed" color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW="container.xl" py={4} spacing={4} justify="center" align="center">
          <LogoSection />
          <Stack direction={{base: "column", sm: "row"}} spacing={6} my={8}>
            {Links.map((link) => (
              <Link key={link.friendly} href={link.path} textAlign={'center'}>{link.friendly}</Link>
            ))}
          </Stack>
        </Container>

        <Box borderTopWidth={1} borderStyle="solid" borderColor={useColorModeValue('gray.200', 'gray.700')}>
          <Container as={Stack} maxW="6xl" py={4} direction={{ base: 'column', md: 'row' }} spacing={4} justify={{ base: 'center', md: 'space-between' }} align={{ base: 'center', md: 'center' }}>
            <Text>© 2023 Webletize. All rights reserved</Text>
            <SocialLinks />
          </Container>
        </Box>
      </Box>
    </>
  );
}