import {
    Box,
    Container,
    Button,
    Text,
    Image,
    VStack,
} from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import TriangleBG from '../../images/misc/triangle-bg.png';

const TEXT_COLOR = '#f9f9f9';
const BG_COLOR = '#2d3748';
const BUTTON_BG_COLOR = '#3443b4';
const BUTTON_HOVER_BG_COLOR = '#929ad5';

export default function FooterConsultation({ onOpen }) {
    const [footerConsultationRef, footerConsultationInView] = useInView({
		triggerOnce: true,
		threshold: 0.5
	});
    return (
        <Container maxW={'container.lg'} position={'relative'} ref={footerConsultationRef} className={`fade-in-section ${footerConsultationInView ? 'visible' : ''}`} mb={{ base: 0, lg: -44, xl: -60 }} px={{ base: 0, lg: 4 }} py={{ base: 14, md: 28 }}>
            <Box width={'100%'} bgColor={BG_COLOR} p={8} textAlign={'center'} position={'relative'} overflow={'hidden'} boxShadow='dark-lg' rounded={{ base: 'none', lg: '2xl' }}>
                <Image src={TriangleBG} alt={'Background Fade'} position={'absolute'} top={0} left={0} w={'100%'} h={'100%'} minW={850} />
                <VStack textColor={TEXT_COLOR} fontWeight={'bold'}>
                    <Text fontSize={{ base: 'xl', sm: '2xl', lg: '4xl' }}>Empower Your Business with Webletize</Text>
                    <Button
                        size={'lg'}
                        fontWeight={'bold'}
                        px={6}
                        onClick={onOpen}
                        colorScheme={'blue'}
                        alignSelf={'center'}
                        maxW={'lg'}
                        w={'100%'}
                        bg={BUTTON_BG_COLOR}
                        _hover={{ bg: BUTTON_HOVER_BG_COLOR }}>
                        Reach Out!
                    </Button>
                </VStack>
            </Box>
        </Container>
    );
}

FooterConsultation.propTypes = {
    onOpen: PropTypes.func.isRequired,
};