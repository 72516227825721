import React from 'react';
import {Helmet} from 'react-helmet-async';
import SplitHero from '../../components/banners/splitHero/splitHero';
import SplitSection from '../../components/sections/splitSection/SplitSection';
import OurProcess from '../../components/sections/ourProcess/OurProcess';
import FooterConsultation from '../../components/cta/footerConsultation';


export default function Homepage(props) {
	const schema = {
		"@context": "http://www.schema.org",
		"@type": "ProfessionalService",
		"name": "Webletize",
		"url": "https://webletize.com",
		"logo": "https://www.webletize.com/assets/logo-schema.png",
		"image": "https://www.webletize.com/assets/open-graph-default.png",
		"description": "We don't just build websites; we create engaging, responsive, and user-friendly online experiences that captivate and convert.",
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "Houston",
			"addressRegion": "Texas",
			"addressCountry": "United States"
		},
		"openingHoursSpecification": {
			"@type": "OpeningHoursSpecification",
			"dayOfWeek": [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday"
			],
			"opens": "08:00",
			"closes": "17:00"
		},
		"telephone": "(281) 829 0048"
	}
	const JSONschema = JSON.stringify(schema)

	return (
		<>
			<Helmet>
				<title>Webletize - Premier Website Design, Development, Marketing</title>
				<meta name="description" content="We don't just build websites; we create engaging, responsive, and user-friendly online experiences that captivate and convert." />
				<link rel="canonical" href="https://webletize.com/" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Webletize - Premier Website Design, Development, Marketing" />
				<meta property="og:url" content="https://webletize.com" />
				<meta property="og:image" content="https://webletize.com/assets/open-graph-default.png" />
				<meta property="og:description" content="We don't just build websites; we create engaging, responsive, and user-friendly online experiences that captivate and convert." />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Webletize - Premier Website Design, Development, Marketing" />
				<meta name="twitter:site" content="@webletize" />
				<meta name="twitter:description" content="We don't just build websites; we create engaging, responsive, and user-friendly online experiences that captivate and convert." />
				<meta name="twitter:image" content="https://webletize.com/assets/twitter-card-default.png" />
				<meta name="twitter:image:alt" content="Webletize - Premier Website Design, Development, Marketing" />
				<script type='application/ld+json'>{JSONschema}</script>
			</Helmet>

			
			<SplitHero onOpen={props.onOpen} />
			<SplitSection />
			<OurProcess />
			<FooterConsultation onOpen={props.onOpen} />
		</>
	);
}