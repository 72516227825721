import { HelmetProvider, Helmet } from 'react-helmet-async';
import React, { Suspense }  from 'react';
import { Routes, Route, } from 'react-router-dom';
import Nav from './pages/nav/Nav';
import Footer from './pages/footer/Footer';
import Home from './pages/home/Home';
import Loading from './components/loading/Loading';
import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import './App.css';
import './fonts/Righteous-Regular.ttf'
import './index.css';

const About = React.lazy(() => import('./pages/about/About'));
const Services = React.lazy(() => import('./pages/services/Services'));
const ThinkTank = React.lazy(() => import('./pages/thinkTank/ThinkTank'));
const Contact = React.lazy(() => import('./pages/contact/Contact'));
const SiteModal = React.lazy(() => import('./components/modal/Modal'));
const ConsultationForm = React.lazy(() => import('./components/forms/consultation/Consultation'));
const Post = React.lazy(() => import('./pages/thinkTank/posts/Post'));
const NotFound = React.lazy(() => import('./pages/404/404'));
    
export default function App() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <HelmetProvider>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-3TJLGES7MY"></script>
                <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-3TJLGES7MY');`}</script>
                <script src="/_vercel/insights/script.js" defer="" data-sdkn="@vercel/analytics" data-sdkv="1.0.2" data-debug="false"></script>
            </Helmet>
            <ChakraProvider>
                <div>
                    <Nav />
                    <Routes>
                        <Route path="/" element={<Home onOpen={onOpen} />} />
                        <Route
                            path="about"
                            element={ 
                            <Suspense fallback={<Loading />}>
                                <About onOpen={onOpen} />
                            </Suspense>}
                        />
                        <Route
                            path="services"
                            element={ 
                                <Suspense fallback={<Loading />}>
                                    <Services onOpen={onOpen} />
                                </Suspense>
                            }
                        />
                        <Route
                            path="think-tank"
                            element={ 
                                <Suspense fallback={<Loading />}>
                                    <ThinkTank onOpen={onOpen} />
                                </Suspense>
                            }
                        />
                        <Route
                            path="contact"
                            element={ 
                                <Suspense fallback={<Loading />}>
                                    <Contact onOpen={onOpen} />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/think-tank/:postSlug"
                            element={ 
                                <Suspense fallback={<Loading />}>
                                    <Post />
                                </Suspense>
                            }
                        />
                        <Route path="*"
                            element={ 
                                <Suspense fallback={<Loading />}>
                                    <NotFound />
                                </Suspense>
                            } 
                            status={404}/>
                    </Routes>
                    <Footer />
                    <Suspense fallback={''}>
                        <SiteModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} showClose={false} size={'2xl'} body={<ConsultationForm close={onClose} />} />
                    </Suspense>
                </div>
                
            </ChakraProvider>
        </HelmetProvider>
    );
}
