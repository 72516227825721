import React, {useState, useEffect} from 'react';
import classes from './nav.module.css';

import logo from '../../images/logos/logo-75.png';
import {
  Container,
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Image,
  useDisclosure,
  Stack,
  Text
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Links = [
  {friendly:'Home', path: '/'},
  {friendly: 'About', path: '/about'},
  {friendly: 'Services', path: '/services'},
  {friendly: 'Think Tank', path: '/think-tank'},
  {friendly: 'Contact', path: '/contact'}
];

export default function Nav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [navFixed, setNavFixed] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
          setNavFixed(window.scrollY > 100);
      });
  }, []);

  return (
    <Container maxW='full' className={`${classes.topNavBar} ${navFixed ? classes.navFixed : ''} ${isOpen ? classes.navOpen : ''}`}>
        <Flex h={'75px'} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'center'}>
            <Box as={'a'} href={'/'} className={classes.logo}>
                <Image maxHeight={{base:50, md: 75}} src={logo} alt="Logo" /> 
                <Text className={classes.logoHeader} fontSize={{base: 28, md: 33, lg:36}} style={{fontFamily: 'Lexend Exa, sans-serif'}} letterSpacing={{base: '-2px', md: '-3px'}} color={'#383838'}>Webletize</Text>
            </Box>
          </HStack>
          <Flex alignItems={'center'}>
            <HStack
              pr={8}
              as={'nav'}
              spacing={8}
              fontSize={{base: 16, lg: 18}}
              display={{ base: 'none', md: 'flex' }}>
                {Links.map((link) => (
                  <Link 
                    href={link.path} 
                    key={link.friendly} 
                    fontWeight={'bold'}
                    className={classes.navLink}
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >{link.friendly}</Link>
                ))}
            </HStack>
            <IconButton
              size={'md'}
              fontSize={'xl'}
              bgColor='transparent'
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
          </Flex>
        </Flex>

        {isOpen ? (
          <Box className={classes.mobileNavWrap} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4} className={classes.mobileNav}>
              {Links.map((link) => (
                <Link
                  key={'mobile-' + link.path}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: '#efefef'
                  }}
                  href={link.path}>
                  {link.friendly}
                </Link>
              ))}
            </Stack>
          </Box>
        ) : null}
    </Container>
  );
}