import React from 'react'
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    Link,
    Button,
    Box,
    StackDivider,
    Divider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { useInView } from 'react-intersection-observer';
  import {
    IoBarChartOutline,
    IoBrushOutline,
    IoCashOutline,
    IoConstructOutline,
    IoEarthOutline,
    IoImagesOutline,
  } from 'react-icons/io5';
  import Smoke from '../../../images/misc/smoke.png';
  import svgDottedBG from '../../../images/svg/svgDottedBG.svg';
  
  const features = [
    { text: 'Websites', icon: IoEarthOutline },
    { text: 'E-commerce', icon: IoCashOutline },
    { text: 'Marketing', icon: IoImagesOutline },
    { text: 'Design', icon: IoBrushOutline },
    { text: 'Maintenance', icon: IoConstructOutline },
    { text: 'SEO', icon: IoBarChartOutline },
  ];
  
  const Feature = ({ text, icon: IconComponent, iconBg }) => {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });
    return(
        <Stack 
            ref={ref}
            className={`overflow-hidden fade-in-section ${inView ? 'visible' : ''}`}
            direction={'row'} align={'center'} spacing={{ base: 8, md: 2, xl: 8 }} py={2}>
            <Flex w={{ base: 12, md: 10, lg: 12 }} h={{ base: 12, md: 10, lg: 12 }} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
                <Icon as={IconComponent} color={'gray.500'} w={{ base: 8, md: 6, lg: 8 }} h={{ base: 8, md: 6, lg: 8 }} />
            </Flex>
            <Text fontSize={{ base: 'lg', md: 'md', lg: 'lg' }} fontWeight={600}>{text}</Text>
        </Stack>
    )
  };
  
  export default function SplitSection() {
    const iconBg = useColorModeValue('gray.200', 'gray.900');

    const [imageRef, imageInView] = useInView({
		triggerOnce: true,
		threshold: 0.1
	});
    const [rightRef, rightInView] = useInView({
		triggerOnce: true,
		threshold: 0.1
	});
    const [headingRef, headingInView] = useInView({
		triggerOnce: true,
		threshold: 0.1
	});
    const [contentRef, contentInView] = useInView({
		triggerOnce: true,
		threshold: 0.1
	});
    const [buttonRef, buttonInView] = useInView({
		triggerOnce: true,
		threshold: 0.2
	});
    return (
        <Container as={'section'} maxW={'full'} minH={'calc(100vh - 75px)'} px={0} style={{ backgroundImage: `url(${svgDottedBG})`, backgroundRepeat: 'repeat' }}>
            <SimpleGrid py={{ base: 20, lg: 48 }} columns={{ base: 1, md: 2 }} spacing={{ base: 20 }} overflow={'hidden'} ref={rightRef}>
                <Flex position={'relative'} ref={imageRef} className={`fade-in-left-fast ${imageInView ? 'visible' : ''}`}>
                    <Image
                        rounded={'none'}
                        alt={'Services'}
                        borderRadius={{ base: '0% 0% 0% 0%', md: '0% 50% 50% 0%' }}
                        src={Smoke}
                        objectFit={'cover'}
                    />
                </Flex>
                <Stack spacing={8} pr={{base: 2, md: 8}} pl={{base: 4, md: 0}} order={{base:-1, md:1}} className={`fade-in-right-fast ${rightInView ? 'visible' : ''}`}>
                    <Heading 
                        ref={headingRef} className={`fade-in-section ${headingInView ? 'visible' : ''}`}
                        lineHeight={1} 
                        fontWeight={400} 
                        fontSize={{ base: '4xl', lg: '5xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: '#d5d8ec',
                                zIndex: -1,
                                }}>
                            Our Digital
                        </Text> &nbsp;
                        <Text as={'span'} display={'block'} color={'#409485'} fontWeight={600}  fontSize={{ base: '5xl', lg: '7xl' }} fontFamily={'Lexend Exa, sans-serif'} letterSpacing={'-5px'}>
                            Expertise
                        </Text>
                    </Heading>
                    <Text color={'gray.500'} fontSize={{ base: 'lg', lg: 'xl' }} ref={contentRef} className={`fade-in-section ${contentInView ? 'visible' : ''}`}>
                        At Webletize, we offer a wide range of digital services designed to elevate your online presence. 
                        We specialize in crafting user-focused websites and optimizing e-commerce sites on leading platforms like Shopify and Big Commerce, providing a seamless and engaging user experience. 
                        Our creative team designs captivating marketing materials that resonate with your audience and align perfectly with your brand. 
                        Regular maintenance services ensure your site remains secure, updated, and performing at its best, while our strategic SEO services enhance your visibility in search engine results, 
                        driving more organic traffic and growth to your business.
                    </Text>
                    <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={{ base: 0, md: 10 }}>
                        <Stack spacing={4} divider={<StackDivider borderColor={useColorModeValue('gray.200', 'gray.500')} />}>
                        {features.slice(0, 3).map((feature, index) => (
                            <Feature key={index} icon={feature.icon} iconBg={iconBg} text={feature.text} />
                        ))}
                        </Stack>
                        <Divider my={{base:4, sm:0}} display={{base: 'block', sm: 'none'}} borderColor={useColorModeValue('gray.200', 'gray.500')} />
                        <Stack spacing={4} divider={<StackDivider borderColor={useColorModeValue('gray.200', 'gray.500')} />}>
                        {features.slice(3).map((feature, index) => (
                            <Feature key={index} icon={feature.icon} iconBg={iconBg} text={feature.text} />
                        ))}
                        </Stack>
                    </SimpleGrid>
                    <Box ref={buttonRef} className={`fade-in-section ${buttonInView ? 'visible' : 'test'}`}>
                        <Link href='/services' _hover={{textDecoration: 'none'}}>
                            <Button
                                size={'lg'}
                                w={{ base: '100%' }}
                                boxShadow='2xl'
                                fontWeight={'bold'}
                                px={6}
                                colorScheme={'blue'}
                                bg={'#3443b4'}
                                _hover={{ bg: '#929ad5' }}>
                                Learn About Our Services
                            </Button>
                        </Link>
                    </Box>
                    
                </Stack>
            </SimpleGrid>
        </Container>
    );
  }