import React, {Suspense} from 'react'
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import HomeWave from '../../../images/misc/home-wave.png';
const SvgBlob = React.lazy(() => import('../../../images/svg/svgBlob'));


const HeroContent = ({ onOpen }) => (
  <Stack flex={1} spacing={{ base: 8 }}>
    <Heading
      as={'h1'}
      lineHeight={1}
      fontWeight={400}
      fontSize={{ base: '4xl', lg: '5xl' }}>
      <Text
        as={'span'}
        position={'relative'}
        _after={{
          content: "''",
          width: 'full',
          height: '30%',
          position: 'absolute',
          bottom: 1,
          left: 0,
          bg: '#D2E6E7',
          zIndex: -1,
        }}>
        Design With
      </Text> &nbsp;
      <Text as={'span'} display={'block'} mt={1} color={'#3443b4'} fontWeight={600} fontSize={{ base: '5xl', lg: '7xl' }} fontFamily={'"Lexend Exa", sans-serif'} letterSpacing={'-5px'}>
        Webletize
      </Text>
    </Heading>
    <Text color={'gray.500'} fontSize={{ base: 'lg', lg: 'xl' }}>
      Webletize, Houston's premier web design and digital marketing firm, crafts digital masterpieces that propel businesses into the digital age with style and efficacy. 
      We don't just build websites; we create engaging, responsive, and user-friendly online experiences that captivate and convert. 
      Let's transform your online vision into a thriving reality - with our expertise, your digital dreams are within reach. 
      Book your consultation today and embark on your journey to digital excellence!
    </Text>
    <Button
      size={'lg'}
      w={{ base: '100%', lg: 'auto' }}
      fontWeight={'bold'}
      boxShadow='2xl'
      onClick={onOpen}
      px={6}
      colorScheme={'blue'}
      bg={'#3443b4'}
      _hover={{ bg: '#929ad5' }}>
      Book A Consultation
    </Button>
  </Stack>
);

const HeroImage = () => (
  <Flex
    flex={1}
    justify={'center'}
    align={'center'}
    position={'relative'}
    maxW={{ sm: 'container.sm' }}
    h={{ base: '200px', md: '350px' }}
    w={{ base: '270px', md: '390px' }}>
      <Suspense fallback={''}>
        <SvgBlob w={'150%'} h={'150%'} position={'absolute'} top={'-30%'} left={0} zIndex={1} color={'#D2E6E7'} />
      </Suspense>
    <Box
      position={'relative'}
      overflow={'hidden'}
      top={0}
      left={0}
      zIndex={1}
      h={{ base: '200px', md: '350px' }}
      w={{ base: '100%', md: '390px' }}
      opacity={'30%'}
      transform={'rotate(-5deg)'}
      backgroundImage={'radial-gradient(#1a202c 2px,transparent 0)'}
      backgroundSize={'30px 30px'}>
    </Box>
  </Flex>
);

export default function SplitHero(props) {
  return (
    <Box as={'section'}
      style={{ backgroundImage: `url(${HomeWave})` }}
      minH={'100vh'}
      pt={{base: '75px'}}
      overflow={'hidden'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'bottom'}
    >
      <Container 
        maxW={'container.xl'}
        py={{ base: 20, lg: 48 }}
      >
        <Stack
          align={'center'}
          spacing={{ base: 10, md: 8 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <HeroContent onOpen={props.onOpen} />
          <HeroImage />
        </Stack>
      </Container>
    </Box>
  );
}