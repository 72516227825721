import React from 'react';
import { Spinner, VStack } from '@chakra-ui/react';

const Loading = () => {
  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      h="100vh"
      w="100vw"
      spacing={4}
      bg="gray.50"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </VStack>
  );
};

export default Loading;