import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import {
  FcCollaboration,
  FcBullish,
  FcApproval,
  FcCommandLine,
  FcGallery
} from 'react-icons/fc';
import Circles from '../../../images/misc/circles-2.png';

const cards = [
  { heading: 'Conceptualize', icon: FcCollaboration, step: "'1'", description: 'We kick off our process with an in-depth discussion to understand your vision, goals, and requirements for the project.' },
  { heading: 'Visualize', icon: FcGallery, step: "'2'", description: 'Our design phase involves translating your ideas into a visually stunning and intuitive web design, aligning with your brand aesthetic.' },
  { heading: 'Digitize', icon: FcCommandLine, step: "'3'", description: 'In the development phase, we bring the finalized design to life with robust, secure, and efficient code.' },
  { heading: 'Finalize', icon: FcApproval, step: "'4'", description: 'Upon your approval, we rigorously test and polish every element before the final launch, ensuring a flawless live website ready to engage and convert visitors.' },
  { heading: 'Optimize', icon: FcBullish, step: "'5'", description: 'Post-launch, we continue to maintain your site and apply SEO strategies to ensure optimal performance and improved search engine rankings.' }
]

const Card = ({ heading, description, icon, step }) => { 
  const [ref, inView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
  });
  return(
    <Box
      ref={ref}
      className={`overflow-hidden fade-in-section ${inView ? 'visible' : ''}`}
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      backgroundColor="#ffffff"
      boxShadow='xl'
      position='relative'
      p={5}
      _before={{
        content: step,
        position: 'absolute',
        color: '#a9a9a9',
        height: '30px',
        width: '25px',
        fontSize: '36px',
        borderRadius: '30% 0% 30% 0%',
        textAlign: 'center',
        top: 2,
        left: 2,
        bg: '#d5d8ec',
        fontFamily: 'Lexend Exa, sans-serif',
        zIndex: 1,
      }}>
      <Stack align={'center'} spacing={2}>
        <Flex w={16} h={16} align={'center'} justify={'center'} color={'white'} rounded={'full'} bg={'#D2E6E7'}>
          {icon}
        </Flex>
        <Box mt={2} textAlign={'center'}>
          <Heading size="md" fontFamily='Lexend Exa, sans-serif' letterSpacing='-2px'>{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  )
  };

export default function OurProcess() {
  const [ourProcessRef, ourProcessInView] = useInView({
		triggerOnce: true,
		threshold: 0.1
	});
  const [ourProcessBGRef, ourProcessBGInView] = useInView({
		triggerOnce: true,
		threshold: 0.1
	});
  return (
    <Box as={'section'} minH={'calc(100vh - 75px)'} ref={ourProcessBGRef} className={`overflow-hidden fade-in-section-fast ${ourProcessBGInView ? 'visible' : ''}`} p={4} py={{ base: 10, md: 20, lg: 40 }} style={{ backgroundImage: `url(${Circles})` }} backgroundRepeat={'no-repeat'} backgroundPosition={'top'}>
      <Box p={0} ref={ourProcessRef} className={`fade-in-section ${ourProcessInView ? 'visible' : ''}`}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading lineHeight={1}
            fontWeight={400}
            fontSize={{ base: '4xl', lg: '5xl' }}>
            <Text as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: '#D2E6E7',
                zIndex: -1,
              }}>
              Our Process
            </Text>
          </Heading>
          <Text color={'gray.500'} fontSize={{ base: 'lg', lg: 'xl' }}>
            We've streamlined our process into five key stages, each dedicated to transforming your ideas into a digital reality.
            From the initial conceptualization to post-launch optimization, we're with you every step of the way, ensuring your website is a true reflection of your brand, engaging to your audience, and primed for success.
          </Text>
        </Stack>

        <Container maxW={'5xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            {cards.map(({ heading, icon, step, description }, idx) =>(
                <Card key={idx} heading={heading} icon={<Icon as={icon} w={10} h={10} />} step={step} description={description} />
              )
            )}
          </Flex>
        </Container>
      </Box>
    </Box>
  );
}